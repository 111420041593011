:root {
	--white: #fff;
	--yellowish: #FEFDF6;
	
  --gray-100: #e1e1e6;
	--gray-300: #c4c4cc;
	--gray-400: #8d8d99;
	--gray-600: #323238;
	--gray-700: #29292e;
	--gray-800: #202024;
	--gray-900: #121214;
	
  --brand-black: #1a1a1a;
  --brand-green: #30BF00;
  --dark-green: #2A524D;

  --secondary-green: #D7EDA7;
  --light-yellow: #FFE27E;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	background-color: var(--yellowish);
	color: var(--brand-black);
}

body,
input,
textarea,
button {
	font-family: 'Calibri', sans-serif;
	font-weight: 400;
	font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Calibri', sans-serif;
	color: var(--brand-black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
